import React from "react"

const ArrowIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.119"
    height="22.117"
    viewBox="0 0 12.119 22.117"
    className="arrow-svg"
    width={12}
    height={12}
  >
    <defs>
      <style>{`.arrow-svg .a{fill:none;stroke:#103d45;stroke-linecap:round;stroke-width:2.5px;}`}</style>
    </defs>
    <path
      className="a"
      d="M17,28,27,18,17,8"
      transform="translate(-15.939 -6.939)"
    />
  </svg>
)

export default ArrowIcon
